















































































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab6ReagentTable',
  components: {ChemicalLatex, CalculationInput, TableInput, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        molarMassBromobenzene: null,
        volBromobenzene: null,
        densityBromobenzene: null,
        molBromobenzene: null,
        molarMassMagnesium: null,
        massMagnesium: null,
        densityMagnesium: null,
        molMagnesium: null,
        molarMassIodine: null,
        massIodine: null,
        densityIodine: null,
        molIodine: null,
        molarMassEther: null,
        volEther: null,
        densityEther: null,
        molEther: null,
        molarMassCO2: null,
        volCO2: null,
        densityCO2: null,
        molCO2: null,

        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      columns: [
        {label: 'Reagent', component: VTextField, prop: 'reagent'},
        {label: 'MM (g/mol)', component: VTextField, prop: 'molarMass'},
        {label: 'Amount (g or mL)', component: VTextField, prop: 'amount'},
        {label: 'Density (g/mL)', component: VTextField, prop: 'density'},
        {label: 'Moles (mmol)', component: VTextField, prop: 'mmol'},
      ],
      columnsFr: [
        {label: 'Réactif', component: VTextField, prop: 'reagent'},
        {label: 'MM (g/mol)', component: VTextField, prop: 'molarMass'},
        {label: 'Quantité (g ou mL)', component: VTextField, prop: 'amount'},
        {label: 'Densité (g/mL)', component: VTextField, prop: 'density'},
        {label: 'Moles (mmol)', component: VTextField, prop: 'mmol'},
      ],
    };
  },
});
